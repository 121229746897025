import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import Link from '../components/Link';
import ArrowIcon from '../assets/icons/arrow-next.svg';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ObserverSection from '../components/ObserverSection';

const Editions = () => {
  const data = useStaticQuery(graphql`
    query editionsRecap {
      allWpEdition {
        nodes {
          editionSettings {
            active
            descriptionEn
            descriptionFr
            fieldGroupName
            nameEn
            nameFr
            url
          }
          id
          title
        }
      }
    }
  `);

  const editions = data?.allWpEdition?.nodes || [];

  const intl = useIntl();
  const { locale } = intl;

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: 'editions.title' })} />
      <div className="page-title">
        {intl.formatMessage({ id: 'editions.title' })}
      </div>
      <div className="page-title-underline" />
      <div className="editions-layout">
        {editions
          .sort((a, b) => parseInt(a?.title) - parseInt(b?.title))
          .map(edition => (
            <ObserverSection className="edition-layout" key={edition.id}>
              <div className="page-subtitle">
                {locale === 'en' && edition.editionSettings?.nameEn
                  ? edition.editionSettings?.nameEn
                  : edition.editionSettings?.nameFr}
              </div>
              <div className="edition-description">
                {locale === 'en' && edition.editionSettings?.descriptionEn
                  ? edition.editionSettings?.descriptionEn
                  : edition.editionSettings?.descriptionFr}
              </div>
              {edition.editionSettings?.active && (
                <Link
                  to={
                    edition.editionSettings?.active
                      ? edition.editionSettings?.url
                      : '#'
                  }
                  className="edition-link-button"
                >
                  {intl.formatMessage({
                    id: edition.editionSettings?.active
                      ? 'editions.discover'
                      : 'editions.apply',
                  })}
                  <ArrowIcon />
                </Link>
              )}
            </ObserverSection>
          ))}
      </div>
    </Layout>
  );
};

export default Editions;
